import {useEffect, useRef, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {IconArrowUpRight} from './IconArrowUpRight';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}

export function Button({
  children,
  className,
  style,
  isLoading,
  ...props
}: ButtonProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [hover, setHover] = useState(false);

  const disabled = props.disabled || isLoading;

  useEffect(() => {
    if (ref.current) {
      if (hover) {
        ref.current.style.opacity = '1';
      } else {
        ref.current.style.opacity = '0';
      }
    }
  }, [hover]);

  return (
    <button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={twMerge(
        'relative bg-blue-dark text-white p-4 rounded-full text-center font-medium flex gap-2 items-center justify-center overflow-hidden',
        disabled ? 'opacity-50 cursor-wait' : '',
        className
      )}
      style={{
        background:
          'linear-gradient(135.36deg, #424CD5 9.71%, #4452D3 9.72%, #25A4F8 100%)',
        ...style,
      }}
      {...props}
      disabled={disabled}
    >
      <span
        ref={ref}
        className="absolute inset-0 rounded-full"
        style={{
          opacity: 0,
          transition: 'opacity 0.5s',
          background:
            'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(135.36deg, #424CD5 9.71%, #4452D3 9.72%, #25A4F8 100%)',
        }}
      />

      <span className="z-10">{children}</span>

      <IconArrowUpRight className="z-10" />
    </button>
  );
}
